<template>
  <section class="block container-padding">
    <section class="block block-padding-right margin-bottom-new padding-mobile-none margin-mobile-none">
      <div class="wrap__map-farm">
        <div class="block-title title-padding-mobile"><h3 class="title-h3-margin">Карта ферм</h3></div>
        <div class="map-farm__block">
          <div id="map">
            <yandex-map
              style="width: 100%; height: 100%;"
              :coords="coordActiveMap"
              :zoom="zoomMap"
              :scroll-zoom="false"
            >
              <div v-for="item in postsBlock2" :key="item.id">
                <ymap-marker
                  marker-id="Ферма"
                  :hint-content="item.title"
                  @click="scrollToPost(item.id)"
                  :icon="markerIcon"
                  :coords="[item.map_lat, item.map_lng]"
                />
              </div>
            </yandex-map>
            <div :class="{active: filtersOpen, 'map-farm__info--active': isActive }" class="map-farm__info">
              <a href="javascript:void(0)" class="color-block-mob map-farm__mobile-btn ">
                Параметры карты
                <span v-on:click="hideMapParams()">
                  <img src="../assets/img/icon-cross-black.svg" height="24" width="29"/>
                </span>
              </a>
              <div @click="filtersOpen = !filtersOpen" :class="{active: filtersOpen}" class="map-farm__info-title">
                <span class="text">На этой карте отображаются фермерские хозяйства, на которых я побывал</span>
                <span class="btn"></span>
              </div>
              <div :class="{active: filtersOpen}" class="map-farm__filterbox">
                <div class="map-farm__filter">
                  <div class="map-farm__filter-title">Сортировка:</div>
                  <div class="map-farm__filter-select">
                    <select @change="chooseType($event)">
                      <option selected disabled>Фильтры</option>
                      <option v-for="type in getCategories.data.results" :key="type.id" :value="type.id">
                        {{ type.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="swiper map-scroll map-farm__info-list">
<!--                  <div class="map-farm__info-list">-->
                    <div class="map-farm__info-block" :id="idInPost + item.id" v-for="item in postsBlock2" :key="item.id">
                      <div @click="openPost(item.id)" :class="{'map-farm__info-cover--active': activeEl === item.id}" class="map-farm__info-cover">
                        <a class="map-farm__info-cover-link "><img :src="pathToImage + item.video_preview_pic" alt="farm-image" /></a>
                      </div>
<!--                      <div>{{ item.map_lat }}</div>-->
<!--                      <div>{{ item.map_lng }}</div>-->
                      <a @click="openPost(item.id, [item.map_lat, item.map_lng])" :class="{'map-farm__info-block-title--active': titleElem === item.id}" class="map-farm__info-block-title">{{ item.title }}</a>
                      <div class="map-farm__info-bottom">
                        <div v-html="item.address_name" class="map-farm__info-place"></div>
                        <div class="map-farm__info-time">Выпуск от {{ item.date.split('-').reverse().join('.') }}</div>
                      </div>
                    </div>

<!--                  </div>-->
                  <div class="map-scrollbar"></div>
                </div>
              </div>
            </div>
            <a v-on:click="showMapParams()" class="map-farm__mobile-btn map-farm__button-open color-mob-block-btn">Параметры карты</a>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import {yandexMap, ymapMarker} from "vue-yandex-maps";
import metka from '@/assets/img/icon-metka.png'
// import NewsItem from './NewsItem.vue';
// import CustomLink from './UI/CustomLink.vue';

export default {
  name: 'YandexMap',

  data () {
    return {
      activeEl: false,
      filtersOpen: true,
      isActive: false,
      loading: true,
      post: null,
      titleElem: 0,
      coordActiveMap: [55.586237, 38.501137],
      coordActiveMapFarm: null,
      zoomMap: 7,
      metka,
      idInPost: 'map-post-id-',
      scrollCounter: 1,
      pathToImage: 'https://fondsvoe.ru/media/static/images/',
      // coords: ,
      markerIcon: {
        layout: 'default#imageWithContent',
        imageHref: '',
        //imageHref: 'https://fondsvoe.ru/media/static/images/icon-metka.png',
        imageSize: [29, 24],
        imageOffset: [0, 0],
        // content: 'Ферма',
        contentOffset: [0, 15],
        contentLayout: '<div style="background: red; width: 50px; color: #FFFFFF; font-weight: bold;">$[properties.iconContent]</div>'
      }
    }
  },
  created() {
    this.markerIcon.imageHref = this.metka
  },
  computed: {
    ...mapState('posts', ['posts']),
    ...mapState('posts', ['tag']),
    ...mapState('category', ['category']),
    getCategories() {
      return this.category;
      // return this.category;
    },
    postsBlock2 () {
      return this.posts.results
    },


  },

  async mounted() {
    // await this.getPostsMap({ tag: this.tag, page: this.page, pageSize: 8 })
    // if (this.mainPosts.length === 0) {
    //   await this.getNews({page: 1, pageSize: 6 })
    // }
  },

  methods: {
    ...mapActions('posts', ['getPosts']),
    ...mapActions('category', ['getPostsTypes']),
    ...mapActions('posts', ['sortPostType']),
    ...mapMutations('posts', ['resetPosts']),
    openPost(id, coord) {

      if (this.activeEl !== id) {
        this.coordActiveMap = coord;
        // this.coordActiveMap = null;

        setTimeout(() => this.zoomMap = 13, 1000);

        this.activeEl = id;

      } else {
        // this.$router.push(`/post/${id}`)
        let routeUrl = '/post/' + id
        window.open(routeUrl, '_blank');
      }
    },
    async showMapParams () {
      this.isActive = true
    },
    async hideMapParams () {
      this.isActive = false
    },
    scrollToPost (id) {

      if (this.filtersOpen === false) {
        this.filtersOpen = true;
      }

      this.titleElem = id;

      this.activeEl = id;
      this.isActive = true;
      const mapBlock = document.querySelector('.map-scroll');
      let gg = document.getElementById(this.idInPost + id);
      let itemImage = document.querySelector(this.idInPost + id + ' .map-farm__info-cover-link');
      let titleElem = document.getElementsByClassName(this.idInPost + id + '.map-farm__info-block-title')


      /* eslint-disable */


      // Дали Vue перендерить блко в фото и после это высчитать высоту
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      sleep(1).then(() => {
        mapBlock.scrollTo({
          top: gg.offsetTop - 126,
          behavior: "smooth"
        })
      });

    },
    async chooseType(el) {
      this.resetPosts()
      this.loading = true
      this.page = 1
      this.selectedType = el.target.value
      const gg = await this.sortPostType({tag: this.tag, page: this.page, pageSize: 8, elemID: this.selectedType}, {})
      // await this.getPosts({page: this.page, pageSize: 8, typeId: this.activeTab})
      // console.log(gg);
      // console.log(el.target.value);
      this.loading = false
    }
    // openPost(id) {
    //   this.$router.push(`/post/${id}?release_slug=${id}`)
    // },
  },

  components: { yandexMap, ymapMarker, Swiper, SwiperSlide},

};
</script>

<style>
.map-farm__info-cover--active {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
  margin-top: auto;
  display: block;
}
.map-farm__info--active {
  opacity: 1;
  visibility: unset;
}
.color-block-mob {
  color: #343434 !important;
}
.color-mob-block-btn {
  color: white !important;
  text-align: center;
  display: block !important;
}
@media (max-width: 575px) {
  .map-farm__mobile-btn {
    color: #343434;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .show-all-cats ul li:nth-child(100) ~ li {
    display: block;
  }
}
</style>

