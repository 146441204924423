<template>
  <section class="contacts-container fade">
    <section class="block container-padding block-padding-right margin-bottom-new">
      <div class="wrap__dir-quote">
        <div class="dir-quote__left-block">
          <div class="dir-quote__name">Андрей Даниленко</div>
          <div class="dir-quote__speech">«Это интерактивный гид по проекту «СВОЁ». Здесь я рассказываю о тех производствах, в которых я лично побывал и убедился в качестве их работы. Постарался создать инструмент, который поможет вам найти своего фермера, приобрести фермерскую продукцию и найти место для отдыха всей семьей вдали от городской суеты»</div>
          <div class="dir-quote__signature">
            <img :src="require('@/assets/img/userlogo.svg')" alt="" class="userlogo">
          </div>
        </div>
        <div class="dir-quote__right-block">
          <img src="../../assets/img/dir.png" height="408" width="546" class="dir-quote__photo" alt="Андрей Даниленко"/>
          <!--<img :src="'./scr/ass/img/dir.png'" class="dir-quote__photo" alt="Андрей Даниленко" />-->
        </div>
      </div>
    </section>
    <YandexMap/>
    <Posts />
    <div class="container-padding">
      <FormBlock/>
      <ContactsExt />
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Feedback from '../../components/Feedback.vue';
import Posts from "@/components/Posts.vue";
import FormBlock from "@/components/FormBlock.vue";
import YandexMap from "@/components/YandexMap.vue";
import ContactsExt from "@/components/ContactsExtBlock.vue";
export default {
  name: 'Contacts',

  data() {
    return {
      isFeedback: true,
      coords: [
        55.74688951017591,
        37.59906281390646
      ],
      address: '',
      settings: {
        apiKey: '',
        lang: this.$t('mapLang'),
        coordorder: 'latlong',
        version: '2.1'
      }
    }
  },

  computed: {
    ...mapState(['baseInfo']),
  },

  methods: {
    ...mapActions(['getBaseInfo']),
    changeFeedback(val) {
      this.isFeedback = val
    },
    handlerMap(map) {
      map.behaviors.disable('multiTouch');
      map.behaviors.disable('drag');
    }
  },

  async mounted() {
    if (!this.baseInfo) {
      await this.getBaseInfo()
    }
    this.address = this.baseInfo.address
  },

  components: {YandexMap, Posts, FormBlock, Feedback, ContactsExt },
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.inline-grid-container{
  width: 100%;
}
.title-wrapper {
  grid-column: 1/5;
}
.contacts-wrapper {
  grid-column: 5/13;
  padding: 0 !important;
  margin-top: 55px;
}
.map {
  width: 100%;
  height: 540px;
  border-radius: $border-radius;
  overflow: hidden;
  margin-bottom: 56px;
}
@media screen and (max-width: 1100px) {
  .contacts-wrapper {
    grid-column: 1/13;
    margin-top: 46px;
  }
  .title-wrapper {
    grid-column: 1/13;
  }
  .map{
    padding: 0 !important;
    margin-top: 28px;
    margin-bottom: 48px;
  }
}

#map {
  width: 100%;
  height: 100vh;
}

.ymap-container {
  height: 100%;
}

.ymapClass{
  border-radius: 20px;
}
.wrap__dir-quote{padding:0 50px 0 105px; height: 412px;background: rgba(19, 165, 56, 0.07) url(/src/assets/img/list-dir.png) no-repeat bottom left;border: 1px solid #E9E9E9;border-radius: 20px; display:flex; flex-wrap:wrap; align-items:center}
.dir-quote__name{font-weight: 600;font-size: 30px;line-height: 34px;color: #343434; padding-bottom:30px}
.dir-quote__speech{font-weight: 500;font-size: 16px;line-height: 32px;color: #6C6C6C}
.dir-quote__signature{text-align:right; font: 32px/40px 'Marck Script', cursive; letter-spacing: -0.07em; color: #000000;}
.dir-quote__left-block{width:640px}
.dir-quote__right-block{width:calc(100% - 640px); text-align:right; padding-left:30px}
.dir-quote__photo{transform:translateY(-10px);max-width: 100%;height: auto;}
@media(max-width:1300px){
  .wrap__dir-quote{padding:0 30px; height: 320px}
  .dir-quote__name{font-size: 26px;line-height: 30px; padding-bottom:20px}
  .dir-quote__speech{font-size: 15px;line-height: 28px}
  .dir-quote__signature{font: 28px/36px 'Marck Script', cursive}
}
@media(max-width:1100px){
  .wrap__dir-quote{padding:0 20px}
  .dir-quote__name{font-size: 24px;line-height: 28px; padding-bottom:15px}
  .dir-quote__speech{font-size: 14px;line-height: 26px}
  .dir-quote__signature{font: 26px/32px 'Marck Script', cursive}
  .dir-quote__left-block{width:540px}
  .dir-quote__right-block{width:calc(100% - 540px); padding-left:20px}
}
@media(max-width:991px){
  .wrap__dir-quote{padding:0 15px; height: 300px; background-size:100px auto}
  .dir-quote__name{font-size: 22px;line-height: 26px}
  .dir-quote__speech{font-size: 13px;line-height: 24px}
  .dir-quote__signature{font: 24px/28px 'Marck Script', cursive}
  .dir-quote__left-block{width:370px}
  .dir-quote__right-block{width:calc(100% - 370px)}
}
@media(max-width:767px){
  .wrap__dir-quote{padding:30px 15px; height: auto}
  .dir-quote__name{font-size: 18px;line-height: 34px}
  .dir-quote__speech{font-size: 12px;line-height: 180%}
  .dir-quote__left-block{width:300px}
  .dir-quote__right-block{width:calc(100% - 300px)}
}
@media(max-width:620px){
  .wrap__dir-quote{padding:0 0 35px; height: auto}
  .dir-quote__name{padding-bottom:10px}
  .dir-quote__signature{padding-top:10px}
  .dir-quote__left-block{width:100%; order:2; padding:0 15px}
  .dir-quote__right-block{width:100%; order:1; padding:0 10px}
}
</style>
